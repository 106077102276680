<template>
  <div
    ref="mdlFacturasAnticipadas"
    class="modal fade" data-bs-backdrop="static"
    data-bs-keyboard="false" tabindex="-1" role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header bg-success">
          <h5 class="modal-title text-truncate text-white">
            {{ id_cliente == null ? 'Selecionar cliente' : 'Facturas anticipadas' }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cerrar()"
          ></button>
        </div>
        <div class="modal-body" v-if="id_cliente == null">
          <filtrador
            v-on:dato-actualizado="
              dato = $event,
              refrescarClientes()
            "
            :datoini="dato"
            placeholder="Dato del cliente"
          ></filtrador>
          <br>
          <div class="table-responsive" style="min-height: 350px;">
            <table id="tblClientes" class="table table-hover table-striped">
              <thead>
                <tr>
                  <th style="width: 70px">#ID</th>
                  <th>Nombre</th>
                  <th style="width: 70px">Selec</th>
                </tr>
              </thead>
              <tbody id="clientes">
                <tr v-for="cliente in clientes" :key="cliente.id">
                  <td class="fw-semibold">{{ cliente.id }}</td>
                  <td class="fw-semibold">{{ cliente.nombre_completo }}</td>
                  <td>
                    <button
                      class="btn btn-outline-success btn-sm"
                      @click="seleccionarCliente(cliente)"
                    >
                      <i class="mdi mdi-check-outline"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="modal-body" v-if="id_cliente != null">
          <p>
            ¿A qué contrato deseas asignar las facturas anticipadas del cliente 
            "<strong>{{nombreCompleto()}}</strong>"?
          </p>
          <div class="row mb-4">
            <div class="col-lg-6 mb-2">
              <label>Contrato asignado</label>
              <select
                ref="contrato"
                v-model="id_contrato" class="form-select">
                <option :value="null">Seleccionar</option>
                <option
                  v-for="id in idsContratos"
                  :value="id"
                  :key="id"
                >
                  {{ id }}
                </option>
              </select>
            </div>
            <div class="col-lg-6 mb-2">
              <label>Número de facturas a generar</label>
              <div class="input-group">
                <span class="input-group-text">
                  Mes{{ cantidad_meses != 1 ? 'es' : '' }}
                </span>
                <input
                  type="number"
                  name="meses"
                  ref="meses"
                  class="form-control text-center"
                  min="0"
                  oninput="this.value = Math.abs(this.value)"
                  v-model="cantidad_meses"
                />
              </div>
            </div>
          </div>
          <div class="d-flex align-items-start">
            <div class="flex-grow-1">
              <button
                class="btn btn-light btn-sm"
                data-bs-dismiss="modal"
                @click="cerrar()"
              >
                <i class="mdi mdi-cancel"></i>
                Cancelar
              </button>
            </div>
            <div class="flex-shrink-0">
              <button
                class="btn btn-success btn-sm"
                @click="generar()"
                :disabled="bandera_spinner"
              >
                <i
                  class="mdi"
                  :class="!bandera_spinner ? 'mdi-cog' : 'mdi-concourse-ci mdi-spin'"
                ></i>
                Generar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FacturaSrv from "@/services/FacturaSrv.js"
import ClienteSrv from '@/services/ClienteSrv.js'
import Filtrador from '@/components/Filtrador.vue'
import Modal from 'bootstrap/js/dist/modal'
export default {
  name: 'MdlFacturasAnticipadas',
  components: { Filtrador },
  data() {
    return {
      id_cliente: null,
      cliente: {
        id: null,
        nombre: '',
      },
      dato: '',
      clientes: [],

      idsContratos: [],

      id_contrato: null,
      cantidad_meses: 1,

      bandera_spinner: false
    }
  },
  methods: {
    cargarCliente: function(idCliente = null, callback) {
      var self = this

      ClienteSrv.clienteJSON(idCliente).then(response => {
        let cliente = response.data
        self.cliente = cliente
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarIdsContratosCliente: function(idCliente = null) {
      var self = this

      ClienteSrv.idsContratos(idCliente).then(response => {
        self.idsContratos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cerrar() {
      var self = this

      self.id_cliente = null
      self.cliente = {
        id: null,
        nombre: '',
      }
      self.dato = ''
      self.clientes = []
      self.idsContratos = []
      self.id_contrato = null
      self.cantidad_meses = 1
      self.bandera_spinner = false
      
      var modal = Modal.getInstance(self.$refs.mdlFacturasAnticipadas)
      modal.hide()
    },

    generar: function() {
      var self = this, generar = {}

      self.bandera_spinner = true

      if(self.id_cliente == null) {
        iu.msg.warning('Se requiere un cliente para generar facturas anticipadas')
        self.bandera_spinner = false
        return
      }

      if (self.id_contrato == null) {
        iu.msg.warning('Se requiere un contrato seleccionado para generar facturas anticipadas')
        self.$refs.contrato.focus()
        self.bandera_spinner = false
        return
      }

      if(self.cantidad_meses <= 0 || self.cantidad_meses == null || self.cantidad_meses == '') {
        iu.msg.warning('Se requiere por lo menos 1 mes para generar facturas anticipadas')
        self.$refs.meses.focus()
        self.bandera_spinner = false
        return
      }

      generar = {
        id_cliente: self.id_cliente,
        id_contrato: self.id_contrato,
        cantidad_meses: self.cantidad_meses
      }

      FacturaSrv.generarFacturasAnticipadas(generar).then(response => {
        iu.msg.success('Se generaron correctamente las facturas anticipadas '+self.nombreCompleto())
        self.$emit('facturas:anticipadas:actualizar')
        self.cerrar()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo generar las facturas anticipadas'
        }
        iu.msg.error(mensaje)
        console.log(error)
        self.$emit('facturas:anticipadas:actualizar')
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    mostrar(idCliente = null) {
      let self = this
      self.id_cliente = idCliente

      if(idCliente != null) {
        self.cargarCliente(idCliente, function() {
          self.cargarIdsContratosCliente(idCliente)
        })
      }

      var modal = new Modal(self.$refs.mdlFacturasAnticipadas)
      modal.show()
    },

    nombreCompleto: function() {
      var nm = '', self = this
      
      nm += self.cliente.nombre
      if (self.cliente.apellido_paterno != null) nm += ' ' + self.cliente.apellido_paterno
      if (self.cliente.apellido_materno != null) nm += ' ' + self.cliente.apellido_materno

      return nm
    },

    refrescarClientes: function() {
      var self = this

      iu.spinner.mostrar('#tblClientes')

      var params = {
        dato: self.dato,
        campos: ['id', 'nombre', 'apellido_paterno', 'apellido_materno']
      }
      
      ClienteSrv.filtradosJSON(params).then(response=>{
        let res = response.data
        self.clientes = res.clientes
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo obtener los clientes'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tblClientes')
      })
    },

    seleccionarCliente: function(cliente) {
      var self =this
      
      self.id_cliente = cliente.id

      self.cargarCliente(cliente.id, function() {
        self.cargarIdsContratosCliente(cliente.id)
      })
    }
  }
}
</script>

<style scoped>
</style>